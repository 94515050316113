<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0"> {{
              $t("message.edit_m", {
                m: $t("message.slider"),
              })
            }}</p>
          <div>
             <crm-store-update-close
                :permission="$options.name"
                :button_type="'store'"
                :loading="loadingButton"
                @c-submit="submit(true)"
                @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->
      <div class="app-modal__body p-5 pb-0" v-loading="loadingData">
        <div class="timeline-items__right rounded-sm w-100 p-4">
          <el-form ref="form" :model="form" :rules="rules" label-position="top">
              <el-row :gutter="20">
                  <el-col :span="24">
                    <el-form-item prop="text" :label="$t('message.text')">
                      <el-input
                        type="textarea"
                        :placeholder="$t('message.text')"
                        v-model="form.text"
                        size="medium"
                        autosize
                      >
                      </el-input>
                    </el-form-item>     
                  </el-col>

                  <el-col :span="24">
                    <el-form-item  prop="is_active" :label="$t('message.status')" >
                      <el-switch
                        v-model="form.is_active"
                        :active-text="$t('message.active')"
                        :inactive-text="$t('message.inactive')">
                      </el-switch>
                    </el-form-item>     
                  </el-col>

                  <el-col :span="24">
                    <el-form-item :label="$t('message.image')" >
                      <el-upload
                        class="avatar-uploader"
                        :show-file-list="false"
                        action="/"
                        :on-change="updateImageList"
                        list-type="picture-card"
                        accept="image/jpeg, image/png"
                        :auto-upload="false"
                      >
                        <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                        <i v-else class="el-icon-plus"></i>
                      </el-upload>
                    </el-form-item>
                  </el-col>
              </el-row>
          </el-form>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import show from "@/utils/mixins/show";
export default {
    mixins: [form, drawer, show],
    name: "slider",
    data() {
        return {
          updateImage: [],
          imageUrl: "", 
        };
    },
    created() {},
    computed: {
        ...mapGetters({
            rules: "sliders/rules",
            model: "sliders/model",
            columns: "sliders/columns",
        }),
    },
    methods: {
        ...mapActions({
            update: "sliders/update",
            show: "sliders/show",
        }),
        
        fetchData() {
          if (!this.loadingData) {  
              this.loadingData=true        
              this.show(this.selectedItem.id).then(res => {
                  if (this.form.image) {
                    this.imageUrl = this.form.image;
                  } 
                  this.loadingData = false              
              }).catch(err => {
                  this.loadingData = false
              });
          }
        },

        updateImageList(file) { 
          if(file.size < 5200000){
            this.imageUrl = URL.createObjectURL(file.raw);
            this.updateImage = [];
            this.updateImage.push(file.raw);
          }else{
            this.$message({
              type: "warning",
              message: "Размер изображения должен быть меньше 5 МБ"
            });
          }
        },
        
        submit(close = true) {
            let formData = new FormData();

            for (const key in this.form) {
              if (key != "image") {
                if (this.form[key]) {
                  formData.append(key, this.form[key]);
                } else {
                  formData.append(key, "");
                }
              }
            }
            if (_.size(this.updateImage) > 0) {
              for (const key in this.updateImage) {
                if (this.updateImage.hasOwnProperty(key)) {
                  const element = this.updateImage[key];
                  formData.append("image", element);
                }
              }
            }

            formData.append('_method', 'PUT');
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.loadingButton = true;
                    this.update(formData)
                        .then((res) => {
                            this.loadingButton = false;
                            this.$alert(res);
                            this.parent().listChanged();
                            if (res.status == 200) this.close();
                        })
                        .catch((err) => {
                            this.loadingButton = false;
                            this.$alert(err);
                        });
                }
            });
        },
    },
};
</script>
